<template>
    <b-container fluid>
        <vue-context ref="rightClickWeekMenu" v-slot="{ data }" :closeOnScroll="false">
            <template v-if="data != null">
                <li class="text-left" @click="copyWeek(data)">
                    <a href="#" >Kopieren</a>
                </li>
                <li v-if="((copyPasteSettings.copy.week != data.week || copyPasteSettings.copy.year != data.year) || (copyPasteSettings.week != null && copyPasteSettings.year != null)) && copyPasteSettings.type == 'week'" @click="pasteWeek(data)">
                    <a href="#">Einfügen</a>
                </li>
                <li v-if="data.workers.length > 0" @click="storeTemplate(data)">
                    <a href="#">Speichern als Template</a>
                </li>
                <li @click="pasteTemplate(data)" v-if="copyPasteSettings.type == 'template'">
                    <a href="#">Einfügen vom Template</a>
                </li>
            </template>
        </vue-context>
        <vue-context ref="rightClickTemplateMenu" v-slot="{ data }" :closeOnScroll="false">
            <template v-if="data != null">
                <li class="text-left" @click="copyTemplates(data)">
                    <a href="#" >Kopieren</a>
                </li>
                <!-- <li class="text-left" @click="deleteTemplate(data)">
                    <a href="#" >Löschen</a>
                </li> -->
            </template>
        </vue-context>
        <div class="card mt-3">
            <div class="card-header">
                <div class="card-title">
                    Arbeitsplanung
                </div>
            </div>
            <div class="card-body">
                <b-tabs content-class="mt-3"  card nav-class="scrollable-tabs-nav" small
                        active-nav-item-class="font-weight-bold text-uppercase" fill>
                    <b-tab 
                        v-for="(template) in templates" 
                        :key="template.id"
                        lazy 
                        :ref="'tab-' + template.id" 
                        >
                        <template #title> 
                            <div @contextmenu.prevent.stop="openRightClickTemplateMenu($event, {template: template})">
                                {{ template.name }}
                            </div>
                        </template>
                    </b-tab>
                </b-tabs>
                <b-tabs content-class="mt-3"  card nav-class="scrollable-tabs-nav" small
                        active-nav-item-class="font-weight-bold text-uppercase" fill @activate-tab="changeTab">
                    <b-tab 
                        v-for="(week) in weeks" 
                        :key="week.id + ' ' + Math.random()"
                        lazy 
                        :title-link-class="generateClass(week)"
                        :ref="'tab-' + week.id" 
                        >
                        <template #title> 
                            <div  @contextmenu.prevent.stop="openRightClickWeekMenu($event, { week: week.week, year: week.year, workers: week.workers })">
                                {{ week.start.format('DD.MM.Y') + ' - ' + week.stop.format('DD.MM.Y') }}
                            </div>
                        </template>
                        <p>
                            <Weekview v-on:has_changes="setChanges" :week="week" :employeeGroups="employeeGroups" :stores="stores" v-on:get_weeks="getWeeks"></Weekview>
                        </p>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </b-container>
</template>

<script>
import moment from 'moment';
import Weekview from './components/Weekview'
import VueContext from 'vue-context';
export default {
    name: 'Workplan',
    title: 'Mitarbeiterplanung',
    components: {
        Weekview,
        VueContext
    },
    data() {
        return {
            hasChanges: false,
            weeks: [],
            formatedWeeks: [],
            year: moment().format('Y'),
            currentWeek: moment().isoWeek(),
            employeeGroups: [],
            stores: [],
            templates: [],
            range: {
                min: 3,
                max: 10,
            },
            copyPasteSettings: {
                copy: {
                    week: null,
                    year: null
                },
                paste: null,
                type: 'week'
            },
        }
    },
    methods: {
        deleteTemplate(template) {
            this.$swal({
                    title: "Möchtest du das Template " + template.template.name + " wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, löschen.`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.axios
                            .delete('/workplan-templates/' + template.template.id)
                            .then(() => {
                                this.$swal({
                                    icon: 'success',
                                    position: 'top-end',
                                    timer: 3000,
                                    timerProgressBar: true,
                                    toast: true,
                                    showConfirmButton: false,
                                    title: "Erfolgreich gelöscht."
                                });
                                this.getTemplates();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Da ist wohl etwas schiefgelaufen'
                                })
                            })
                    }
                });
        },
        storeTemplate(workers) {
            this.$swal({
                cancelButtonText: 'Abbrechen',
                confirmButtonText: 'Ja, Speichern',
                input: 'text',
                inputLabel: 'Name',
                inputPlaceholder: 'Template Namen eingeben...',
                inputAttributes: {
                    'aria-label': 'Template Namen eingeben'
                },
                showCancelButton: true
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.axios
                            .post('/workplan-templates', {
                                workers: workers.workers,
                                name: result.value
                            })
                            .then(() => {
                                this.getTemplates();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Da ist wohl etwas schief gelaufen'
                                })
                            });
                    }
                })
        },
        getTemplates() {
            this.axios
                .get('/workplan-templates')
                .then((response) => {
                    this.templates = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim Speichern von einem Template ist etwas schief gelaufen'
                    })
                })
        },
        openRightClickWeekMenu(event, data) {
            this.$refs.rightClickWeekMenu.open(event, data);
        },
        openRightClickTemplateMenu(event, data) {
            this.$refs.rightClickTemplateMenu.open(event, data);
        },

        copyWeek(data) {
            this.copyPasteSettings.copy = data;
            this.copyPasteSettings.type = 'week';
            this.$refs.rightClickWeekMenu.close();
        },
        copyTemplates(data) {
            console.log(data);
            this.copyPasteSettings.copy.week = data.template.id;
            this.copyPasteSettings.type = 'template';
            this.$refs.rightClickTemplateMenu.close();
        },
        pasteWeek(data) {
            if(this.hasChanges == true)
            {
                this.$swal({
                    title: "Bitte speicher deine Änderungen bevor Du die Woche kopierst",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: 'Abbrechen',
                    showConfirmButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                });
            } else
            {
                this.$swal({
                    title: "Möchtest du die Kalenderwoche " + this.copyPasteSettings.copy.week +" nach " + data.week + " kopieren?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, kopieren.`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.axios.post("/workplan-week/copy", {
                            copy_week: this.copyPasteSettings.copy.week,
                            copy_year: this.copyPasteSettings.copy.year,
    
                            paste_week: data.week,
                            paste_year: data.year,
                        })
                        .then(() => {
                            this.copyPasteSettings.copy = {week: null, year: null}
                            this.getWeeks();
                            this.$swal({
                                icon: 'success',
                                position: 'top-end',
                                timer: 3000,
                                timerProgressBar: true,
                                toast: true,
                                showConfirmButton: false,
                                title: "Die woche wurde kopiert."
                            });
                        })
                        .catch(() => {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Da ist wohl etwas schief gelaufen'
                            })
                        })
                    }
                });
            }
        },
        pasteTemplate(data) {
            if(this.hasChanges == true)
            {
                this.$swal({
                    title: "Bitte speicher deine Änderungen bevor Du die Woche kopierst",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: 'Abbrechen',
                    showConfirmButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                });
            } else
            {
                this.$swal({
                    title: "Möchtest du die Kalenderwoche " + this.copyPasteSettings.copy.week +" nach " + data.week + " kopieren?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, kopieren.`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.axios.post("/workplan-week/copy-template", {
                            workplan_template_id: this.copyPasteSettings.copy.week,
    
                            paste_week: data.week,
                            paste_year: data.year,
                        })
                        .then(() => {
                            this.copyPasteSettings.copy = {week: null, year: null}
                            this.getWeeks();
                            this.$swal({
                                icon: 'success',
                                position: 'top-end',
                                timer: 3000,
                                timerProgressBar: true,
                                toast: true,
                                showConfirmButton: false,
                                title: "Die woche wurde kopiert."
                            });
                        })
                        .catch(() => {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Da ist wohl etwas schief gelaufen'
                            })
                        })
                    }
                });
            }
        },
        getStores() {
            this.axios
                .get('/stores')
                .then((response) => {
                    this.stores = response.data.data;
                    this.stores.push({
                        id: 0,
                        name: 'Schule',
                    })
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oos...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        },
        getWeeks() {
            this.axios
                .get("/workplan-weeks",
                {
                    params: {
                        
                        minweek: moment().week(this.currentWeek).subtract(this.range.min, 'weeks').format('Y-M-D'),
                        maxweek: moment().week(this.currentWeek).add(this.range.max, 'weeks').format('Y-M-D'),
                    }
                })
                .then((response) => {
                    const minDate = moment().subtract(this.range.min, 'week').startOf('isoWeek');
                    const maxDate = moment().add(this.range.max, 'week').endOf('isoWeek');
                    var allWeeks = this.generateWeeks(minDate.format('Y-M-D'), maxDate.format('Y-M-D'));
                    var weeks = response.data.data;
                    this.weeks = [];
                    allWeeks.forEach((element) => {
                        let itemWeek = weeks.find(item => item.week == element.week && item.year == element.year);
                        if(itemWeek != undefined && itemWeek != null)
                        {
                            this.weeks.push({
                                id: itemWeek.id,
                                week: itemWeek.week,
                                year: itemWeek.year,
                                active: itemWeek.active,
                                start: moment().isoWeekYear(itemWeek.year).isoWeek(itemWeek.week).startOf('isoWeek'),
                                stop: moment().isoWeekYear(itemWeek.year).isoWeek(itemWeek.week).endOf('isoWeek'),
                                range: this.createRange(moment().isoWeekYear(itemWeek.year).isoWeek(itemWeek.week).startOf('isoWeek').format('Y-M-D'), moment().isoWeekYear(itemWeek.year).isoWeek(itemWeek.week).endOf('isoWeek').format('Y-M-D')),
                                workers: itemWeek.workers
                            })
                        } else
                        {
                            this.weeks.push({
                                id: 0,
                                week: element.week,
                                year: element.year,
                                active: element.active,
                                start: moment().isoWeekYear(element.year).isoWeek(element.week).startOf('isoWeek'),
                                stop: moment().isoWeekYear(element.year).isoWeek(element.week).endOf('isoWeek'),
                                range: this.createRange(moment().isoWeekYear(element.year).isoWeek(element.week).startOf('isoWeek').format('Y-M-D'), moment().isoWeekYear(element.year).isoWeek(element.week).endOf('isoWeek').format('Y-M-D')),
                                workers: [],
                            })
                        }
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden ist etwas schief gelaufen'
                    })
                });
        },
        getEmployeeGroups()
        {
            this.axios
                .get('/employee/groups')
                .then((response) => {
                    const employeeGroups = response.data.data;

                    employeeGroups.forEach(element => {
                        var workers = [];
                        element.employees.forEach(employee => {
                            workers.push({
                                id: employee.id,
                                employee: employee,
                                store_id: null,
                                workday: null,
                                start_time: '08:00',
                                stop_time: '13:30',
                            })
                        })
                        this.employeeGroups.push({
                            id: element.id,
                            name: element.name,
                            employees: workers
                        })
                    })
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Mitarbeitergruppen ist etwas schief gelaufen'
                    })
                })
        },
        generateClass(week) {
            return week.active == 1 ? 'bg-light ' : 'bg-danger bg-gradient';
        },
        generateWeeks(firstDate, lastDate) {
            var weeks = [];
            const dates = this.createRange(firstDate, lastDate);
            dates.forEach(element => {
                let exists = weeks.find(item => (item.week == element.isoWeek() && item.year == element.year()))
                if(exists == null)
                {
                    weeks.push({
                        id: 0,
                        week: element.isoWeek(),
                        year: element.year(),
                        active: 0,
                        start: element.startOf('isoWeek'),
                        stop: element.endOf('isoWeek'),
                    });
                }
            });
            return weeks;
        },
        createRange(startDate, stopDate) {
            let dates = [];
            let currentDate = moment(this.parseDate(startDate));
            while(currentDate.isSameOrBefore(this.parseDate(stopDate)))
            {
                dates.push(moment(currentDate).locale('DE'));
                currentDate = currentDate.add(1, 'days');
            }
            return dates;
        },
        parseDate(date)
        {
            let element = date.split(/[\s-:]+/);
            return {
                year: parseInt(element[0]),
                month: parseInt(element[1])-1,
                date: parseInt(element[2]),
            }
        },
        setChanges(changes = true) {
            this.hasChanges = changes;
        },
        changeTab(newIndex, oldIndex, bvEvent) {
            if(this.hasChanges == true)
            {
                bvEvent.preventDefault();
                this.$swal({
                    title: "Bitte speicher deine Änderungen bevor du die Woche wechselst",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: 'Abbrechen',
                    showConfirmButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                });
            }
        },
    },
    mounted() {
        this.getWeeks();
        this.getStores();
        this.getEmployeeGroups();
        this.getTemplates();
    },
    beforeRouteLeave(to, from, next)
    {
        if(this.hasChanges == true)
        {
            this.$swal({
                title: "Möchtest du die Änderungen wirklich verwerfen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, verwerfen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.hasChanges = false;
                    next(true);
                } else
                {
                    next(false);
                }
            });
        } else
        {
        next(true);
        }
    }
}
</script>